import './AppMobile.css'

function AppMobile(){
    return(
        <>
        <div className = "Background-Image2" align="center">

            <h1 align="center">You were warned</h1>
        </div>
        
        <div>
            <p>I have no idea what to do with this website but I paid 99 cents for the domain.</p>
        </div>

        </>
    );
}

export default AppMobile;